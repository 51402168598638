
.news {
	.col-sm-3 {
		@media (min-width: 320px) and (max-width: 767px) {
			text-align: center;
			margin: 0 0 20px; }
		#datepicker {
			@media (min-width: 320px) and (max-width: 767px) {
				width: 80%;
				margin: 0 auto;
				max-width: 200px; } } } }

.datepicker {
	position: relative;
	cursor: pointer;
	.input-group-addon {
		background: #fff; }
	.form-control {
		background: none;
		cursor: pointer;
		font-family: $aSans; } }
