
.forum-header {
	display: flex;
	justify-content: space-between;
	border: 1px solid $lightGray;
	background: #fff;
	padding: 0 0 0 20px;
	margin-bottom: 20px;
	@media (min-width: 768px) and (max-width: 991px) {
		padding: 0 0 0 10px; }
	@media (max-width: 480px) {
		display: block;
		padding: 0 20px; }
	.datepicker {
		padding: 13px 0;
		@media (min-width: 320px) and (max-width: 991px) {
			padding: 8px 0; }
		span, input {
			border: 0;
			background: none;
			box-shadow: none;
			text-align: center;
			font-family: $aSans;
			padding: 0;
			font-size: 16px;
			@media (max-width: 480px) {
				border: 1px solid $lightGray;
				border-left: none; } }
		span {
			@media (max-width: 480px) {
				border-right: 0;
				padding-left: 10px;
				border-left: 1px solid $lightGray; } } } }

.forum-nav {
	text-align: left;
	li {
		display: inline-block;
		a {
			color: $gray;
			text-decoration: none;
			padding: 20px 10px;
			display: block;
			position: relative;
			font-family: $aSans;
			@media (min-width: 320px) and (max-width: 991px) {
				padding: 15px 10px; } }
		&.active, &:hover {
			a {
				font-weight: 700;
				color: $blue;
				&:after {
					display: block;
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					content: '';
					height: 2px;
					background: $blue; } } } } }
