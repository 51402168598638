
ul.tenders-list {
	overflow: hidden;
	margin: 0;
	padding: 0;
	li {
		width: calc((100% - 40px) / 3);
		margin-right: 20px;
		margin-bottom: 20px;
		float: left;
		border: 1px solid $gray3;
		&:nth-child(3n) {
			margin-right: 0; }
		@media (min-width: 520px) and (max-width: 991px) {
			width: calc((100% - 40px) / 2);
			margin: 0 10px 20px;
			&:nth-child(3n) {
				margin-right: 10px; } }
		@media (min-width: 320px) and (max-width: 519px) {
			width: 100%;
			margin: 0 0 20px;
			&:nth-child(3n) {
				margin-right: 0; } }
		.img-wrapper {
			position: relative;
			overflow: hidden;
			display: block;
			font-size: 0;
			margin-bottom: 20px;
			img {
				position: absolute;
				display: block;
				margin: auto;
				top: -9999px;
				bottom: -9999px;
				right: -9999px;
				left: -9999px;
				z-index: 0;
				max-height: 100%;
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto; } }
		.top {
			padding: 16px 20px;
			border-bottom: 1px solid $gray3; }
		.bottom {
			padding: 16px 20px;
			> div {
				display: flex;
				justify-content: space-between; }
			p {
				font-size: 16px;
				color: $black;
				margin-bottom: 20px;
				span {
					font-size: 14px;
					color: $gray2;
					display: block; }
				i {
					color: $gray2;
					float: left;
					margin-right: 5px;
					margin-top: 5px;
					font-size: 18px; } } }
		h2 {
			font-size: 22px;
			a {
				color: $black; } } } }

.single-tender-content {
	p {
		margin-bottom: 20px;
		font-size: 16px;
		color: $gray; }
	h5 {
		margin-bottom: 0; }
	h3 {
		margin-bottom: 20px; }
	ul {
		padding-left: 20px;
		li {
			list-style: disc;
			font-size: 14px;
			color: $gray; } } }

.other-tenders {
	margin-top: 10px; }

@media (max-width: 768px) {
	.col-md-5 {
		margin-bottom: 30px; } }
