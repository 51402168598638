
.news-content {
	max-width: 820px;
	margin: 0 auto; }
.news-content, .text {
	background: #fff;
	span.date {
		font-size: 16px;
		color: $gray2;
		display: block;
		margin: 0 0 20px;
		@media (min-width: 768px) and (max-width: 991px) {
			margin: 0 0 10px; }
		@media (min-width: 320px) and (max-width: 767px) {
			margin-bottom: 10px;
			font-size: 14px; } }
	h1 {
		font-family: $al;
		font-weight: 700;
		font-size: 32px;
		@media (min-width: 320px) and (max-width: 767px) {
			font-size: 24px; }
		color: $black;
		margin-bottom: 20px; }
	h3 {
		margin-bottom: 30px;
		font-size: 22px;
		font-weight: 700;
		color: $black; }
	h6 {
		font-size: 18px;
		font-family: 'Alegreya', serif;
		margin-bottom: 20px; }
	p {
		font-size: 16px;
		margin-bottom: 20px;
		color: $gray; }
	.load-more {
		margin-top: 20px; }
	.video {
		margin-bottom: 20px;
		@media (min-width: 320px) and (max-width: 480px) {
			iframe {
				max-height: 320px; } } } }


