// main-carousel
.main-carousel {
	margin-bottom: 10px;
	@media (min-width: 320px) and (max-width: 767px) {
		padding: 20px 0; }
	> .row {
		margin: 0; }
	ul.buttons {
		padding-bottom: 0;
		@media (min-width: 768px) and (max-width: 991px) {
			padding-top: 20px; }
		@media (min-width: 320px) and (max-width: 767px) {
			padding: 0; } } }


#myCarousel {
	display: flex;
	@media (min-width: 320px) and (max-width: 767px) {
		display: block;
		padding-bottom: 20px; }
	@media (min-width: 768px) and (max-width: 991px) {
		padding-bottom: 20px; }
	.col-sm-8 {
		padding-right: 30px;
		@media (min-width: 320px) and (max-width: 767px) {
			padding-right: 10px; }
		.row {
			height: 100%; } }
	.carousel-inner {
		height: 100%; }
	.item {
		max-width: 760px;
		height: 100%;
		.content {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between; }
		h2 {
			font-size: 28px;
			font-family: $aSans;
			margin: 0 0 40px;
			color: $black;
			@media (min-width: 768px) and (max-width: 991px) {
				font-size: 24px; }
			@media (min-width: 320px) and (max-width: 767px) {
				margin-bottom: 20px;
				font-size: 20px; } }
		.img-wrapper {
			display: block;
			font-size: 0;
			position: relative;
			img {
				position: absolute;
				display: block;
				margin: auto;
				top: -9999px;
				bottom: -9999px;
				right: -9999px;
				left: -9999px;
				z-index: 0;
				max-height: 100%;
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto; } } }
	.col-sm-4 {
		padding: 40px 10px 0 10px;
		@media (min-width: 320px) and (max-width: 767px) {
			padding: 20px 10px 0; }
		@media (min-width: 768px) and (max-width: 991px) {
			padding: 0 10px; }
		>.row {
			height: 100%; } }
	ol.carousel-indicators {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: static;
		margin: 0;
		width: 100%;
		li {
			text-indent: inherit;
			width: 100%;
			height: auto;
			padding: 0 10px 0 0;
			margin: 0;
			border: 0;
			background: none;
			text-align: left;
			position: relative;
			&:after {
				content: '';
				display: block;
				width: 4px;
				height: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				background: $lightGray; }
			h3 {
				font-size: 18px;
				color: $gray2;
				margin: 0 0 20px;
				@media (min-width: 768px) and (max-width: 991px) {
					margin: 0 0 10px;
					font-size: 17px; }
				@media (min-width: 320px) and (max-width: 767px) {
					font-size: 14px;
					margin: 0 0 10px; } }
			&.active {
				margin: 0;
				h3 {
					color: $black; }
				&:after {
					background: $blue; } }
			&:last-child {
				h3 {
					margin: 0; } } } } }

span.date {
	font-size: 16px;
	color: $gray2;
	display: block;
	margin: 0 0 10px;
	@media (min-width: 320px) and (max-width: 767px) {
		font-size: 12px;
		margin: 0 0 3px; } }

.tag.top {
	font-size: 12px;
	color: #fff;
	font-family: $aSans;
	text-transform: uppercase;
	height: 28px;
	line-height: 28px;
	display: inline-block;
	padding: 0 20px;
	background: $darkBlue;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1; }
