
#myCarousel1 {
	margin-bottom: 40px;
	.img-wrapper {
		position: relative;
		overflow: hidden;
		img {
			position: absolute;
			display: block;
			margin: auto;
			top: -9999px;
			bottom: -9999px;
			right: -9999px;
			left: -9999px;
			z-index: 0;
			max-height: 100%;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto; }
		.tag {
			font-size: 12px;
			color: #fff;
			font-family: $aSans;
			text-transform: uppercase;
			height: 28px;
			line-height: 28px;
			display: inline-block;
			padding: 0 20px;
			background: $darkBlue;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1; } }
	.carousel-control {
		background: none;
		transition: all 0.5s;
		opacity: 0;
		display: inline-flex;
		vertical-align: middle;
		align-items: center;
		justify-content: center;
		.material-icons {
			font-size: 40px; }
		&:hover {
			opacity: 0.7; } } }
