
ul.news-list {
	overflow: hidden;
	padding-bottom: 20px;
	li {
		float: left;
		margin: 0 20px 20px 0;
		width: calc((100% - 40px) / 3);
		&:nth-child(3n) {
			margin: 0 0 20px; }
		@media (min-width: 320px) and (max-width: 767px) {
			width: 100%;
			margin: 0 0 20px; }
		.img-wrapper {
			display: block;
			position: relative;
			overflow: hidden;
			margin-bottom: 20px;
			img {
				position: absolute;
				display: block;
				margin: auto;
				top: -9999px;
				bottom: -9999px;
				right: -9999px;
				left: -9999px;
				z-index: 0;
				max-height: 100%;
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto; }
			.tag {
				font-size: 12px;
				color: #fff;
				font-family: $aSans;
				text-transform: uppercase;
				height: 28px;
				line-height: 28px;
				display: inline-block;
				padding: 0 20px;
				background: $darkBlue;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 1; } }
		span.date {
			font-size: 16px;
			color: $gray2;
			display: block;
			margin: 0 0 20px;
			@media (min-width: 768px) and (max-width: 991px) {
				margin: 0 0 10px; }
			@media (min-width: 320px) and (max-width: 767px) {
				margin-bottom: 10px;
				font-size: 14px; } }
		h4 {
			margin-bottom: 40px;
			@media (min-width: 768px) and (max-width: 991px) {
				margin-bottom: 20px;
				font-size: 16px; }
			a {
				font-size: 18px;
				color: $gray;
				&:hover {
					color: $black; } } }
		.read-more {
			display: inline-block;
			color: $gray2;
			font-size: 14px;
			overflow: hidden;
			line-height: 20px;
			text-transform: uppercase;
			font-family: $aSans;
			i {
				line-height: 20px !important;
				font-size: 20px;
				margin-left: 5px; }
			&:hover {
				color: $black; } } } }

