.lightgallery {
	overflow: hidden;
	padding-top: 20px;
	a {
		width: calc(100% / 4);
		@media (min-width: 320px) and (max-width: 480px) {
			width: 50%; }
		box-sizing: border-box;
		border: 2.5px solid #fff;
		float: left;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 200px;
		overflow: hidden;
		text-decoration: none;
		position: relative;
		&:before {
			z-index: 1;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background: #0E326C;
			content: '';
			transition: all 0.3s;
			opacity: 0; }

		&:after {
			z-index: 2;
			width: 40px;
			height: 40px;
			content: '+';
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -20px;
			margin-top: -20px;
			background: $blue;
			text-align: center;
			line-height: 38px;
			font-size: 18px;
			color: #fff;
			transition: all 0.4s;
			opacity: 0; }
		img {
			max-width: none;
			max-height: none; }
		.element_horizont {
			max-height: 100%; }
		.element_vertical {
			max-width: 100%; }
		&:hover {
			cursor: pointer;
			&:before {
				opacity: .7; }
			&:after {
				opacity: 1; } } } }

.lg-sub-html {
	h4 {
		color: #fff;
		font-family: $aSans; }
	p {
		color: #fff;
		font-family: $aSans; } }
