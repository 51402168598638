// ********** BASIC **********//
@import "assets/basic/_reset";                       // reset.css
@import "assets/basic/_vars";                        // all sass $vars
@import "assets/basic/_basic";                       // basic styles
@import "assets/basic/_elements";                    // UiKit

// ********** PARTIALS **********//
@import "assets/partials/_header";                   // header
@import "assets/partials/_footer";                   // footer
@import "assets/partials/_buttons";                  // buttons
@import "assets/partials/_breadcrumbs";              // breadcrumbs
@import "assets/partials/_pagination";               // pagination
@import "assets/partials/_share";                    // share


// ********** PAGES - index.html  ********** //
@import "assets/pages/index/_carousel";              // main carousel
@import "assets/pages/index/_press";                 // press
// ********** PAGES - news.html  ********** //
@import "assets/pages/news/_datepicker";             // datepicker
// ********** PAGES - news-single.html  ********** //
@import "assets/pages/news-single/_news-content";    // news-content
@import "assets/pages/news-single/_carousel";    // news-content
@import "assets/pages/news-single/_lightgallery";    // news-content
// ********** PAGES - forum.html  ********** //
@import "assets/pages/forum/_main";
@import "assets/pages/forum/_header";
@import "assets/pages/forum/_item";
@import "assets/pages/forum/_profile";
// ********** PAGES - text.html  ********** //
@import "assets/pages/text/_text";
// ********** PAGES - tenders.html  ********** //
@import "assets/pages/tender/_tenders";

.bank, .contacts {
	h3 {
		margin-bottom: 10px; }
	p {
		font-size: 16px;
		color: $black;
		b {
			display: block;
			font-size: 18px;
			font-family: $aSans; } }
	ol {
		list-style: decimal;
		padding-left: 10px;
		li {
			span {
				float: right;
				margin-left: 10px; } } }
	a {
		display: block;
		margin-bottom: 20px;
		i {
			margin-right: 10px; } } }


.contacts {
	p {
		font-size: 18px;
		font-family: $aSans;
		b {
			display: inline-block;
			width: 40%; } } }














