.breadcrumbs {
	background: #EEF2F7;
	height: auto;
	.breadcrumb {
		margin: 0;
		padding: 10px 0;
		font-family: $aSans;
		font-size: 12px;
		background: none;
		.breadcrumb-item {
			a {
				color: $darkBlue; }
			&.active {
				a {
					color: $gray2; } } }
		>li+li:before {
			content: '>'; } } }

