
footer {
	background: $darkBlue;
	.top-side {
		padding: 40px 0;
		border: 1px solid rgba(255,255,255,0.1);
		@media (min-width: 320px) and (max-width: 767px) {
			text-align: center; } }
	.bottom-side {
		padding: 40px 0; }
	.col-sm-3 {
		text-align: center;
		@media (min-width: 320px) and (max-width: 767px) {
			padding-bottom: 20px; }
		.footer-logo {
			display: block;
			text-align: center;
			margin-bottom: 10px;
			img {
				width: 114px;
				height: 80px; } }
		h5 {
			font-size: 14px;
			color: #fff;
			font-weight: 500;
			font-family: $aSans; } }
	p {
		font-size: 11px;
		color: rgba(255,255,255,0.5);
		padding-bottom: 10px;
		@media (min-width: 320px) and (max-width: 767px) {
			text-align: center; } }
	span {
		font-size: 12px;
		display: inline-block;
		color: rgba(255,255,255,0.5); }
	h6 {
		color: #fff;
		font-size: 16px;
		margin-bottom: 16px;
		font-weight: 700;
		text-transform: uppercase; }
	.content {
		display: flex;
		justify-content: space-between;
		@media (min-width: 768px) and (max-width: 1199px) {
			flex-wrap: wrap;
			justify-content: flex-start; }
		@media (min-width: 320px) and (max-width: 767px) {
			flex-wrap: wrap;
			justify-content: flex-start; } }
	ul {
		@media (min-width: 768px) and (max-width: 1199px) {
			width: calc(100% / 3);
			margin-bottom: 20px; }
		@media (min-width: 480px) and (max-width: 767px) {
			width: calc(100% / 2);
			margin-bottom: 20px; }
		@media (min-width: 320px) and (max-width: 479px) {
			width: calc(100% / 2);
			margin-bottom: 20px; }
		li {
			padding-bottom: 10px;
			vertical-align: middle;
			a {
				font-size: 14px;
				color: rgba(255,255,255,0.5);
				font-family: $aSans;
				&:hover, &:focus, &:active {
					color: #ffff; } }
			i {
				color: $green;
				margin-right: 5px; } } }
	.mitapp {
		padding-top: 20px;
		a {
			display: block;
			overflow: hidden;
			img {
				width: 40px;
				height: 38px;
				float: left; }
			span {
				float: left;
				margin: 3px 0 0 10px;
				color: #fff;
				font-size: 12px;
				font-family: $aSans; } } } }

