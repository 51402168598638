
.forum {
	padding: 20px 0;
	background: #E5E5E5;
	main {
		padding: 0 30px 0 0;
		@media (min-width: 768px) and (max-width: 1199px) {
			padding: 0 10px 0 0; }
		@media (max-width: 768px) {
			padding: 0 10px; }
		@media (min-width: 768px) {
			float: left !important; } }
	aside {
		padding: 0 0 0 30px;
		@media (min-width: 768px) and (max-width: 1199px) {
			padding: 0 0 0 10px; }
		@media (max-width: 768px) {
			padding: 0 10px 20px; }
		@media (min-width: 768px) {
			float: right !important; } } }
