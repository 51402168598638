
// fonts
$aSans: Alegreya Sans, serif;
$al: 'Alegreya', serif;

// main colors
$darkBlue: #0E326C;
$blue: #0C59D3;
$gray: #697B93;
$gray2: #9BA8B9;
$gray3: #CFD5DE;
$lightGray: #EEF2F7;
$lightGray: #F1F3F4;

// primary colors
$black: #1F262F;
$red: #DF2025;
$green: #20DFA6;
