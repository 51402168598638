

.auth-reg, .profile {
	background: #fff;
	padding: 30px 20px;
	border: 1px solid $lightGray;
	text-align: center;
	font-family: $aSans;
	margin: 0 0 10px;
	@media (min-width: 320px) and (max-width: 991px) {
		padding: 20px; }
	h3 {
		color: $darkBlue;
		font-size: 22px;
		font-weight: 700;
		font-family: $aSans;
		margin: 0 0 20px;
		@media (min-width: 320px) and (max-width: 991px) {
			font-size: 18px;
			margin-bottom: 10px; } }
	.form-group {
		margin: 0 0 10px;
		display: block;
		input {
			width: 100%;
			height: 56px;
			padding: 0 10px;
			border: 1px solid #CFD5DE;
			border-radius: 4px;
			box-shadow: none;
			font-size: 16px;
			color: $black;
			font-family: $aSans;
			@media (min-width: 320px) and (max-width: 991px) {
				height: 42px;
				line-height: 42px; }
			&::placeholder {
				font-family: $aSans;
				color: $gray2; } } }
	.forgot-pwd, .auth-reg-link {
		display: block;
		margin-bottom: 20px;
		font-size: 16px;
		@media (min-width: 320px) and (max-width: 991px) {
			margin-bottom: 10px; } }
	.main-btn {
		height: 52px;
		line-height: 52px;
		font-size: 14px;
		text-transform: uppercase;
		color: #fff;
		background: $blue;
		border: 0px;
		border-radius: 4px;
		padding: 0;
		display: block;
		width: 100%;
		font-weight: 700;
		margin-bottom: 20px;
		@media (min-width: 320px) and (max-width: 991px) {
			margin-bottom: 10px;
			font-size: 13px;
			height: 40px;
			line-height: 40px; } }
	.social {
		text-align: center;
		span {
			font-size: 12px;
			color: $gray;
			display: block;
			margin-bottom: 20px; }
		li {
			display: inline-block;
			margin: 0 5px;
			@media (min-width: 320px) and (max-width: 991px) {
				margin: 0 3px 0 0; }
			a {
				border: 2px solid $lightGray;
				display: block;
				padding: 0;
				height: 50px;
				width: 50px;
				text-align: center;
				@media (min-width: 320px) and (max-width: 991px) {
					width: 40px;
					height: 40px; }
				img {
					display: block;
					height: 20px;
					margin: 13px auto;
					@media (min-width: 320px) and (max-width: 991px) {
						margin: 8px auto; } }
				&:hover {
					border-color: $gray; } } } } }

.profile {
	padding: 0;
	@media (min-width: 320px) and (max-width: 991px) {
		padding: 0; }
	.user-info {
		display: flex;
		padding: 20px;
		border-bottom: 1px solid $lightGray;
		.img-wrapper {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
			margin: 0 10px 0 0;
			img {
				width: 40px;
				height: 40px; } }
		h6 {
			margin: 0;
			font-size: 18px;
			font-weight: 700;
			color: $gray;
			text-align: left;
			padding: 5px 0;
			span {
				display: block;
				font-size: 12px;
				color: $gray2;
				font-weight: 400;
				a {
					color: $blue;
					margin: 0 0 0 5px; } } } } }

.user-nav {
	text-align: left;
	padding-top: 10px;
	li {
		border-bottom: 1px solid $lightGray;
		i {
			color: $gray3;
			margin-right: 20px; }
		a {
			font-size: 16px;
			font-family: $al;
			color: $gray;
			padding: 15px 20px;
			display: block; } } }

.main-forum-btn {
	width: 100%;
	display: block;
	border: 0;
	font-size: 14px;
	color: #fff;
	padding: 0;
	height: 52px;
	border-radius: 4px;
	background: #20DFA6;
	font-weight: 700;
	font-family: $aSans;
	text-transform: uppercase;
	text-align: center;
	text-wrap: normal;
	line-height: 52px;
	margin-bottom: 10px;
	i {
		margin-right: 5px; }
	&:hover {
		color: #fff;
		background: #20DFA6;
		text-decoration: none; } }
