

.forum-item {
	border: 1px solid $lightGray;
	background: #fff;
	margin-bottom: 20px;
	.top {
		padding: 20px;
		@media (min-width: 320px) and (max-width: 991px) {
			padding: 15px 20px; } }
	h2 {
		margin-bottom: 20px;
		overflow: hidden;
		@media (min-width: 320px) and (max-width: 991px) {
			margin-bottom: 10px; }
		a {
			color: $black;
			font-size: 24px;
			font-family: $aSans;
			width: calc(100% - 25px);
			float: left;
			@media (min-width: 320px) and (max-width: 991px) {
				font-size: 20px; } }
		i {
			float: right; } }
	.img-wrapper {
		overflow: hidden;
		a {
			display: block;
			font-size: 0; }
		img {
			width: 100%;
			height: auto; } }
	.bottom {
		padding: 20px;
		display: flex;
		justify-content: space-between;
		border-top: 1px solid $lightGray;
		@media (min-width: 320px) and (max-width: 991px) {
			padding: 10px 20px; }
		.views-comments {
			display: flex; } }
	.comments, .views {
		font-size: 18px;
		color: $gray;
		font-weight: 700;
		margin-right: 30px;
		line-height: 40px;
		i {
			margin-right: 5px; }
		@media (min-width: 320px) and (max-width: 991px) {
			font-size: 16px; } }
	.user {
		overflow: hidden;
		display: flex;
		.social-icon {
			margin-left: 10px;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
			img {
				width: 40px;
				height: 40px; } }
		h5 {
			font-size: 12px;
			font-family: $aSans;
			color: $black;
			font-weight: 400;
			padding-top: 10px;
			span {
				color: $gray2;
				display: block; } } } }
