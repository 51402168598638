
// Заголовки
h1, h2, h3, h4, h5, h6 {
	color: #1F262F;
	font-family: $aSans;
	font-weight: 700; }

h1 {
	font-size: 32px;
	@media (max-width: 768px) {
		font-size: 28px; } }
h2 {
	font-size: 32px;
	@media (max-width: 768px) {
		font-size: 28px; } }
h3 {
	font-size: 24px;
	@media (max-width: 768px) {
		font-size: 20px; } }
h4 {
	font-size: 22px;
	@media (max-width: 768px) {
		font-size: 18px; } }
h5 {
	font-size: 22px;
	@media (max-width: 768px) {
		font-size: 18px; } }
h6 {
	font-size: 18px;
	@media (max-width: 768px) {
		font-size: 16px; } }

// параграфы
p.main {
	font-size: 18px;
	color: #1F262F; }
p.big {
	font-size: 16px; }
p {
	font-size: 14px; }
p.small {
	font-size: 12px; }



// elements, buttons
.btn.main {
	border: 2px solid #EEF2F7;
	padding-top: 0;
	padding-bottom: 0;
	border-radius: 4px;
	display: inline-block;
	font-size: 14px;
	line-height: 52px;
	height: 52px;
	min-width: 240px;
	background: #fff;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	color: #0C59D3;
	transition: all 0.3s;
	margin-bottom: 10px;
	&:hover {
		border-color: #0C59D3; } }

.btn.fill {
	background: #0C59D3;
	border: 0;
	color: #fff;
	&:hover {
		background: #0A52C6; } }

.btn.big {
	height: 62px;
	line-height: 62px;
	min-width: 320px; }

.links {
	font-size: 16px;
	&.blue {
		color: #0C59D3; }
	&.black {
		color: #1F262F; } }
