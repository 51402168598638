
.text {
	h1 {
		font-size: 32px;
		@media (min-width: 320px) and (max-width: 768px) {
			font-size: 24px; } }
	h2 {
		font-size: 28px;
		@media (min-width: 320px) and (max-width: 768px) {
			font-size: 22px; } }
	h3 {
		font-size: 22px;
		@media (min-width: 320px) and (max-width: 768px) {
			font-size: 18px; } }
	h4 {
		font-size: 22px;
		@media (min-width: 320px) and (max-width: 768px) {
			font-size: 18px; } }
	h5 {
		font-size: 18px;
		@media (min-width: 320px) and (max-width: 768px) {
			font-size: 16px; } }
	h6 {
		font-size: 18px;
		@media (min-width: 320px) and (max-width: 768px) {
			font-size: 16px; } }
	h1, h2, h3, h4, h5, h6, p {
		margin-bottom: 20px;
		@media (min-width: 320px) and (max-width: 768px) {
			margin-bottom: 10px; } } }


.date-container {
	padding: 20px 30px;
	border: 1px solid #CFD5DE;
	margin: 20px 0;
	border-radius: 4px;
	@media (max-width: 992px) {
		padding: 20px;
		text-align: center; } }
.datepicker-container {
	display: flex;
	justify-content: space-between;
	@media (max-width: 992px) {
		display: block;
		text-align: center; }
	.input-group {
		max-width: 260px;
		width: calc((100% - 220px) / 2);
		cursor: pointer;
		padding: 0;
		margin: 0 auto 10px;
		@media (max-width: 992px) {
			width: auto; } }
	button {
		width: 200px;
		height: 52px;
		padding: 0;
		background: $blue;
		text-transform: uppercase;
		color: #fff;
		font-weight: 700;
		border-radius: 4px;
		font-size: 14px;
		font-family: $aSans;
		border: 2px solid $blue;
		transition: all 0.3s;
		&:hover {
			background: #fff;
			border-color: $blue;
			color: $blue; }
		@media (max-width: 768px) {
			height: 42px; } }
	input, span, .form-control {
		background: none;
		border-color: $gray3;
		height: 52px;
		box-sizing: border-box;
		cursor: pointer;
		box-shadow: none;
		@media (max-width: 768px) {
			height: 42px; }
		&:hover, &:focus {
			border-color: $blue; } } }

.panel-group {
	.panel {
		border-radius: 0;
		border: 1px solid $lightGray;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		padding: 20px;
		font-family: $al;
		margin: 0 !important;
		.panel-heading {
			border-radius: 0 !important;
			padding: 0;
			h4 {
				margin: 0;
				span.date {
					color: $gray2;
					font-size: 14px;
					font-weight: 400;
					font-family: $al;
					margin-bottom: 5px; }
				a {
					font-family: $aSans;
					font-size: 18px;
					line-height: 24px;
					display: block;
					margin: 4px 0;
					color: $darkBlue;
					i {
						float: right;
						color: $blue;
						display: none;
						&.remove {
							display: block; } }
					&.collapsed {
						i {
							&.remove {
								display: none; }
							&.add {
								display: block; } } } }
				label {
					font-size: 16px;
					font-weight: 400;
					color: $black;
					font-family: $al; } } }
		.panel-collapse {
			.panel-body {
				padding: 20px 0 0;
				border: none;
				p {
					font-size: 16px;
					color: #000;
					margin-bottom: 10px; }
				ul {
					padding-left: 20px;
					margin-bottom: 20px;
					color: $gray;
					li {
						list-style-type: disc;
						font-size: 14px; } }
				span {
					display: block;
					margin-bottom: 20px;
					color: $gray;
					font-size: 14px;
					a {
						margin-left: 5px;
						color: $blue; } } } } } }
