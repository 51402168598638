@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, * {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-shadow: none;
  vertical-align: baseline;
  box-sizing: border-box; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, button, textarea, button {
  outline: 0;
  box-shadow: none; }
  input:focus, button:focus, textarea:focus, button:focus {
    box-shadow: none;
    outline: 0 !important; }

a {
  transition: all 0.3s;
  outline: 0;
  text-decoration: none;
  color: #0C59D3; }
  a:hover, a:focus {
    background: none;
    outline: 0 !important;
    color: #0C59D3; }

html, body {
  font-size: 16px; }

body {
  font-family: "Alegreya", serif;
  color: #697B93;
  background: #e5e5e5;
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

.content-wrapper {
  flex: 1; }

.container {
  padding-left: 10px;
  padding-right: 10px; }
  .container.posrel {
    position: relative; }
  @media (min-width: 1200px) {
    .container {
      width: 1200px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  .container > .row {
    margin-right: 0;
    margin-left: 0; }

.row {
  margin-right: -10px;
  margin-left: -10px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
  padding-left: 10px;
  padding-right: 10px; }

.nopadding {
  padding: 0 !important; }

.material-icons {
  vertical-align: middle !important; }

section {
  padding: 60px 0;
  background: #fff; }
  @media (max-width: 992px) {
    section {
      padding: 40px 0; } }
  @media (max-width: 768px) {
    section {
      padding: 20px 0; } }
  section h1, section h2 {
    margin: 0 0 40px; }
    @media (min-width: 768px) and (max-width: 991px) {
      section h1, section h2 {
        margin: 0 0 20px; } }
    @media (min-width: 320px) and (max-width: 767px) {
      section h1, section h2 {
        font-size: 28px;
        margin: 0 0 20px; } }

.load-more {
  display: block;
  padding: 16px 0;
  border: 2px solid #F1F3F4;
  font-weight: 700;
  font-family: Alegreya Sans, serif;
  color: #0C59D3;
  text-align: center;
  text-transform: uppercase; }
  .load-more:hover, .load-more:focus {
    border-color: #0C59D3;
    color: #0C59D3; }

.other-news {
  margin-top: 10px; }

.mt10 {
  margin-top: 10px; }

h1, h2, h3, h4, h5, h6 {
  color: #1F262F;
  font-family: Alegreya Sans, serif;
  font-weight: 700; }

h1 {
  font-size: 32px; }
  @media (max-width: 768px) {
    h1 {
      font-size: 28px; } }

h2 {
  font-size: 32px; }
  @media (max-width: 768px) {
    h2 {
      font-size: 28px; } }

h3 {
  font-size: 24px; }
  @media (max-width: 768px) {
    h3 {
      font-size: 20px; } }

h4 {
  font-size: 22px; }
  @media (max-width: 768px) {
    h4 {
      font-size: 18px; } }

h5 {
  font-size: 22px; }
  @media (max-width: 768px) {
    h5 {
      font-size: 18px; } }

h6 {
  font-size: 18px; }
  @media (max-width: 768px) {
    h6 {
      font-size: 16px; } }

p.main {
  font-size: 18px;
  color: #1F262F; }

p.big {
  font-size: 16px; }

p {
  font-size: 14px; }

p.small {
  font-size: 12px; }

.btn.main {
  border: 2px solid #EEF2F7;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  line-height: 52px;
  height: 52px;
  min-width: 240px;
  background: #fff;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  color: #0C59D3;
  transition: all 0.3s;
  margin-bottom: 10px; }
  .btn.main:hover {
    border-color: #0C59D3; }

.btn.fill {
  background: #0C59D3;
  border: 0;
  color: #fff; }
  .btn.fill:hover {
    background: #0A52C6; }

.btn.big {
  height: 62px;
  line-height: 62px;
  min-width: 320px; }

.links {
  font-size: 16px; }
  .links.blue {
    color: #0C59D3; }
  .links.black {
    color: #1F262F; }

.main-navigation {
  background-image: linear-gradient(to right bottom, #0e326c, #0f3777, #103c81, #11418c, #134697);
  z-index: 98;
  border-radius: 0;
  border: 0;
  margin: 0; }
  .main-navigation .navbar-header {
    overflow: hidden;
    padding: 0; }
    .main-navigation .navbar-header .logo {
      margin: 0;
      padding: 17px 0;
      height: auto; }
      .main-navigation .navbar-header .logo img {
        height: 66px;
        width: 94px; }

.search-btn {
  color: #fff;
  line-height: 9px;
  padding: 38px 20px;
  transition: all 0.3s;
  position: relative; }
  .search-btn:hover, .search-btn:focus {
    color: #20DFA6; }
  .search-btn .material-icons {
    font-size: 24px; }
  .search-btn span {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 8px 6px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -4px;
    display: none; }
    .search-btn span.active {
      display: block; }

/** hidden search field * */
#searchbar {
  display: none;
  width: 100%;
  height: auto;
  z-index: 9999;
  position: absolute;
  top: 100px;
  border: none; }
  #searchbar .container {
    overflow: hidden;
    padding-bottom: 20px; }
  #searchbar form {
    padding: 20px;
    float: right !important;
    margin: 0;
    width: 50%;
    position: relative;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 10px 10px; }
    #searchbar form .form-group {
      width: 100%; }

#s {
  display: block;
  width: 100%;
  border: 1px solid #CFD5DE;
  outline: none;
  padding: 18px 15px;
  height: 56px;
  line-height: 20px;
  font-size: 16px;
  color: #697B93;
  box-shadow: none; }
  #s ::-webkit-input-placeholder, #s::placeholder {
    font-family: Alegreya Sans, serif; }

#searchsubmit {
  display: block;
  background: none;
  color: #0C59D3;
  border: 0;
  outline: none;
  cursor: pointer;
  height: 56px;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  font-size: 20px;
  line-height: 12px; }
  #searchsubmit:active {
    box-shadow: none; }

.language {
  float: right;
  padding: 30px 15px;
  overflow: hidden; }
  .language li {
    padding: 0 5px;
    float: left; }
    .language li a {
      width: 40px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      padding: 0;
      border-radius: 50%; }
      .language li a img {
        width: 20px;
        height: 20px;
        margin: auto; }
    .language li.active a, .language li:hover a {
      background: none !important;
      border: 1px solid #20DFA6; }

.main-nav {
  padding-left: 15px; }
  .main-nav li {
    padding: 0 20px; }
    .main-nav li a {
      font-size: 14px;
      padding: 40px 0;
      font-family: Alegreya Sans, serif;
      font-weight: 500;
      color: #fff !important;
      line-height: 20px;
      text-transform: uppercase;
      position: relative; }
      .main-nav li a:after {
        display: none; }
      .main-nav li a .material-icons {
        font-size: 20px;
        line-height: 17px; }
    .main-nav li:first-child a {
      line-height: 20px; }
    .main-nav li.active a, .main-nav li:hover a, .main-nav li:focus a, .main-nav li:focus-within a, .main-nav li.open a {
      color: #20DFA6 !important;
      background: none !important; }
      .main-nav li.active a:before, .main-nav li:hover a:before, .main-nav li:focus a:before, .main-nav li:focus-within a:before, .main-nav li.open a:before {
        width: 100%;
        content: '';
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #20DFA6; }
  .main-nav .dropdown-menu {
    padding: 20px;
    min-width: 240px;
    left: 10px;
    border: 0;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); }
    .main-nav .dropdown-menu li {
      padding: 0; }
      .main-nav .dropdown-menu li a {
        padding: 6px 0;
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 400;
        color: #697B93 !important;
        text-decoration: none; }
        .main-nav .dropdown-menu li a:before {
          display: inline-block;
          content: '» ';
          width: auto;
          height: auto;
          background: none;
          position: static;
          margin-right: 5px; }
        .main-nav .dropdown-menu li a:hover, .main-nav .dropdown-menu li a.active {
          color: #1F262F !important; }

@media (min-width: 992px) and (max-width: 1200px) {
  .main-nav li {
    padding: 0 10px; }
  .language {
    padding: 30px 5px; }
  .search-btn {
    padding-right: 0;
    padding-left: 10px; } }

@media (min-width: 768px) and (max-width: 991px) {
  #searchbar {
    top: 80px; }
  .search-btn {
    margin-right: 50px;
    padding: 28px 20px; }
  .language {
    padding: 20px 10px; }
  .main-navigation .container > .row {
    position: relative; }
  .main-navigation .navbar-toggle {
    margin: 23px 0;
    border-color: #fff;
    position: absolute;
    right: 0;
    transition: all 0.3s; }
    .main-navigation .navbar-toggle .icon-bar {
      background: #fff; }
    .main-navigation .navbar-toggle:hover, .main-navigation .navbar-toggle:focus {
      background: none;
      border-color: #20DFA6; }
      .main-navigation .navbar-toggle:hover .icon-bar, .main-navigation .navbar-toggle:focus .icon-bar {
        background: #20DFA6; }
  .main-navigation .navbar-header .logo {
    padding: 7px 0; }
  .main-navigation .main-nav {
    margin: 0;
    padding: 0;
    text-align: center; }
    .main-navigation .main-nav .dropdown-menu {
      padding: 10px 0;
      text-align: center; }
      .main-navigation .main-nav .dropdown-menu li a {
        color: #fff !important; }
        .main-navigation .main-nav .dropdown-menu li a:active {
          color: #1F262F !important; }
        .main-navigation .main-nav .dropdown-menu li a:before {
          width: auto !important;
          margin: 0 5px 0 0 !important; }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
    .navbar-nav .open .dropdown-menu > li > a {
      line-height: 20px;
      padding: 5px 15px 5px 25px; }
    .navbar-nav .open .dropdown-menu .dropdown-header {
      padding: 5px 15px 5px 25px; }
  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
    box-shadow: none;
    padding: 0; }
    .navbar-collapse.collapse {
      display: none !important; }
  .navbar-nav.main-nav {
    float: none !important;
    /*margin: 7.5px -15px; */
    margin: 0; }
    .navbar-nav.main-nav > li {
      float: none;
      padding: 0; }
      .navbar-nav.main-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px; }
      .navbar-nav.main-nav > li.active a:before, .navbar-nav.main-nav > li:hover a:before, .navbar-nav.main-nav > li:focus a:before, .navbar-nav.main-nav > li:focus-within a:before, .navbar-nav.main-nav > li.open a:before {
        width: 200px;
        left: 50%;
        margin-left: -100px; }
  .navbar-text {
    float: none;
    margin: 15px 0; }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important; }
  .collapsing {
    overflow: hidden !important; } }

@media (min-width: 320px) and (max-width: 767px) {
  #searchbar {
    top: 80px; }
    #searchbar form {
      width: 100%; }
  .search-btn {
    margin-right: 50px;
    padding: 28px 20px; }
  .language {
    padding: 20px 10px; }
  .main-navigation .container > .row {
    position: relative; }
  .main-navigation .navbar-toggle {
    margin: 23px 0;
    border-color: #fff;
    position: absolute;
    right: 0;
    transition: all 0.3s; }
    .main-navigation .navbar-toggle .icon-bar {
      background: #fff; }
    .main-navigation .navbar-toggle:hover, .main-navigation .navbar-toggle:focus {
      background: none;
      border-color: #20DFA6; }
      .main-navigation .navbar-toggle:hover .icon-bar, .main-navigation .navbar-toggle:focus .icon-bar {
        background: #20DFA6; }
  .main-navigation .navbar-header {
    float: left; }
    .main-navigation .navbar-header .logo {
      padding: 7px 0; }
  .main-navigation .main-nav {
    margin: 0;
    padding: 0;
    text-align: center; }
    .main-navigation .main-nav .dropdown-menu {
      padding: 10px 0;
      text-align: center; }
      .main-navigation .main-nav .dropdown-menu li a {
        color: #fff !important; }
        .main-navigation .main-nav .dropdown-menu li a:active {
          color: #1F262F !important; }
        .main-navigation .main-nav .dropdown-menu li a:before {
          width: auto !important;
          margin: 0 5px 0 0 !important; }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
    .navbar-nav .open .dropdown-menu > li > a {
      line-height: 20px;
      padding: 5px 15px 5px 25px; }
    .navbar-nav .open .dropdown-menu .dropdown-header {
      padding: 5px 15px 5px 25px; }
  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
    box-shadow: none;
    padding: 0; }
    .navbar-collapse.collapse {
      display: none !important; }
  .navbar-nav.main-nav {
    float: none !important;
    /*margin: 7.5px -15px; */
    margin: 0; }
    .navbar-nav.main-nav > li {
      float: none;
      padding: 0; }
      .navbar-nav.main-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px; }
      .navbar-nav.main-nav > li.active a:before, .navbar-nav.main-nav > li:hover a:before, .navbar-nav.main-nav > li:focus a:before, .navbar-nav.main-nav > li:focus-within a:before, .navbar-nav.main-nav > li.open a:before {
        width: 200px;
        left: 50%;
        margin-left: -100px; }
  .navbar-text {
    float: none;
    margin: 15px 0; }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important; }
  .collapsing {
    overflow: hidden !important; } }

@media (max-width: 520px) {
  .main-navigation .navbar-header .logo {
    padding: 15px 0; }
    .main-navigation .navbar-header .logo img {
      height: 50px;
      width: auto; } }

@media (max-width: 380px) {
  .main-navigation .navbar-header {
    float: none; }
  #searchbar {
    top: 160px; } }

footer {
  background: #0E326C; }
  footer .top-side {
    padding: 40px 0;
    border: 1px solid rgba(255, 255, 255, 0.1); }
    @media (min-width: 320px) and (max-width: 767px) {
      footer .top-side {
        text-align: center; } }
  footer .bottom-side {
    padding: 40px 0; }
  footer .col-sm-3 {
    text-align: center; }
    @media (min-width: 320px) and (max-width: 767px) {
      footer .col-sm-3 {
        padding-bottom: 20px; } }
    footer .col-sm-3 .footer-logo {
      display: block;
      text-align: center;
      margin-bottom: 10px; }
      footer .col-sm-3 .footer-logo img {
        width: 114px;
        height: 80px; }
    footer .col-sm-3 h5 {
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      font-family: Alegreya Sans, serif; }
  footer p {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 10px; }
    @media (min-width: 320px) and (max-width: 767px) {
      footer p {
        text-align: center; } }
  footer span {
    font-size: 12px;
    display: inline-block;
    color: rgba(255, 255, 255, 0.5); }
  footer h6 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 700;
    text-transform: uppercase; }
  footer .content {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 768px) and (max-width: 1199px) {
      footer .content {
        flex-wrap: wrap;
        justify-content: flex-start; } }
    @media (min-width: 320px) and (max-width: 767px) {
      footer .content {
        flex-wrap: wrap;
        justify-content: flex-start; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    footer ul {
      width: calc(100% / 3);
      margin-bottom: 20px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    footer ul {
      width: calc(100% / 2);
      margin-bottom: 20px; } }
  @media (min-width: 320px) and (max-width: 479px) {
    footer ul {
      width: calc(100% / 2);
      margin-bottom: 20px; } }
  footer ul li {
    padding-bottom: 10px;
    vertical-align: middle; }
    footer ul li a {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
      font-family: Alegreya Sans, serif; }
      footer ul li a:hover, footer ul li a:focus, footer ul li a:active {
        color: #ffff; }
    footer ul li i {
      color: #20DFA6;
      margin-right: 5px; }
  footer .mitapp {
    padding-top: 20px; }
    footer .mitapp a {
      display: block;
      overflow: hidden; }
      footer .mitapp a img {
        width: 40px;
        height: 38px;
        float: left; }
      footer .mitapp a span {
        float: left;
        margin: 3px 0 0 10px;
        color: #fff;
        font-size: 12px;
        font-family: Alegreya Sans, serif; }

ul.buttons {
  margin: 0;
  padding: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (min-width: 320px) and (max-width: 767px) {
    ul.buttons {
      display: block;
      overflow: hidden;
      padding: 20px 0 0; } }
  ul.buttons li {
    margin-right: 20px;
    width: calc((100% - 60px) / 4);
    text-align: center;
    border: 1px solid #F1F3F4;
    background: #fff;
    box-sizing: border-box;
    transition: all 0.3s; }
    ul.buttons li:hover {
      border-color: #0C59D3; }
    ul.buttons li:last-child {
      margin: 0; }
    @media (min-width: 320px) and (max-width: 767px) {
      ul.buttons li {
        width: calc((100% - 20px) / 2);
        float: left; }
        ul.buttons li:nth-child(2n) {
          margin: 0 0 20px 0; } }
    @media (max-width: 480px) {
      ul.buttons li {
        width: 100%;
        margin: 0 0 10px; }
        ul.buttons li:nth-child(2n) {
          margin: 0 0 10px 0; }
        ul.buttons li:last-child {
          margin: 0; } }
    ul.buttons li a {
      font-size: 18px;
      font-family: Alegreya Sans, serif;
      display: block;
      padding: 25px 0;
      color: #0C59D3; }
      @media (min-width: 768px) and (max-width: 991px) {
        ul.buttons li a {
          font-size: 14px;
          padding: 15px 0; } }
      @media (min-width: 320px) and (max-width: 767px) {
        ul.buttons li a {
          font-size: 14px;
          padding: 15px 10px; } }
      ul.buttons li a i {
        margin-right: 10px;
        font-size: 16px; }
      ul.buttons li a:hover, ul.buttons li a:focus {
        color: #0C59D3; }

.breadcrumbs {
  background: #EEF2F7;
  height: auto; }
  .breadcrumbs .breadcrumb {
    margin: 0;
    padding: 10px 0;
    font-family: Alegreya Sans, serif;
    font-size: 12px;
    background: none; }
    .breadcrumbs .breadcrumb .breadcrumb-item a {
      color: #0E326C; }
    .breadcrumbs .breadcrumb .breadcrumb-item.active a {
      color: #9BA8B9; }
    .breadcrumbs .breadcrumb > li + li:before {
      content: '>'; }

.paginations {
  text-align: center; }
  .paginations .pagination .page-item {
    width: 40px;
    height: 40px;
    margin: 0 4px;
    display: inline-block; }
    .paginations .pagination .page-item a {
      padding: 0;
      display: block;
      line-height: 40px;
      font-size: 14px;
      color: #697B93;
      font-family: Alegreya Sans, serif;
      border: 2px solid #F1F3F4;
      width: 40px; }
      .paginations .pagination .page-item a:hover {
        background: none;
        border-color: #0C59D3; }
    .paginations .pagination .page-item.disabled a {
      border: 2px solid #0C59D3; }

.share {
  position: absolute;
  top: 0;
  left: 0; }
  @media (max-width: 991px) {
    .share {
      position: static;
      margin-bottom: 15px; } }

.social li {
  display: block;
  margin: 0 0 5px; }
  @media (min-width: 320px) and (max-width: 991px) {
    .social li {
      margin: 0 3px 0;
      display: inline-block; } }
  .social li a {
    border: 2px solid #F1F3F4;
    display: block;
    padding: 0;
    height: 50px;
    width: 50px;
    text-align: center; }
    @media (min-width: 320px) and (max-width: 991px) {
      .social li a {
        width: 40px;
        height: 40px; } }
    .social li a img {
      display: block;
      height: 20px;
      margin: 13px auto; }
      @media (min-width: 320px) and (max-width: 991px) {
        .social li a img {
          margin: 8px auto; } }
    .social li a:hover {
      border-color: #697B93; }

.download-file {
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  font-size: 16px;
  color: #1F262F;
  padding: 10px 0;
  border-bottom: 1px solid #F1F3F4; }
  .download-file .material-icons {
    transform: rotate(180deg);
    margin-right: 5px;
    color: #0C59D3; }
  .download-file:hover {
    text-decoration: underline;
    color: #0C59D3; }

.main-carousel {
  margin-bottom: 10px; }
  @media (min-width: 320px) and (max-width: 767px) {
    .main-carousel {
      padding: 20px 0; } }
  .main-carousel > .row {
    margin: 0; }
  .main-carousel ul.buttons {
    padding-bottom: 0; }
    @media (min-width: 768px) and (max-width: 991px) {
      .main-carousel ul.buttons {
        padding-top: 20px; } }
    @media (min-width: 320px) and (max-width: 767px) {
      .main-carousel ul.buttons {
        padding: 0; } }

#myCarousel {
  display: flex; }
  @media (min-width: 320px) and (max-width: 767px) {
    #myCarousel {
      display: block;
      padding-bottom: 20px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    #myCarousel {
      padding-bottom: 20px; } }
  #myCarousel .col-sm-8 {
    padding-right: 30px; }
    @media (min-width: 320px) and (max-width: 767px) {
      #myCarousel .col-sm-8 {
        padding-right: 10px; } }
    #myCarousel .col-sm-8 .row {
      height: 100%; }
  #myCarousel .carousel-inner {
    height: 100%; }
  #myCarousel .item {
    max-width: 760px;
    height: 100%; }
    #myCarousel .item .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    #myCarousel .item h2 {
      font-size: 28px;
      font-family: Alegreya Sans, serif;
      margin: 0 0 40px;
      color: #1F262F; }
      @media (min-width: 768px) and (max-width: 991px) {
        #myCarousel .item h2 {
          font-size: 24px; } }
      @media (min-width: 320px) and (max-width: 767px) {
        #myCarousel .item h2 {
          margin-bottom: 20px;
          font-size: 20px; } }
    #myCarousel .item .img-wrapper {
      display: block;
      font-size: 0;
      position: relative; }
      #myCarousel .item .img-wrapper img {
        position: absolute;
        display: block;
        margin: auto;
        top: -9999px;
        bottom: -9999px;
        right: -9999px;
        left: -9999px;
        z-index: 0;
        max-height: 100%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto; }
  #myCarousel .col-sm-4 {
    padding: 40px 10px 0 10px; }
    @media (min-width: 320px) and (max-width: 767px) {
      #myCarousel .col-sm-4 {
        padding: 20px 10px 0; } }
    @media (min-width: 768px) and (max-width: 991px) {
      #myCarousel .col-sm-4 {
        padding: 0 10px; } }
    #myCarousel .col-sm-4 > .row {
      height: 100%; }
  #myCarousel ol.carousel-indicators {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: static;
    margin: 0;
    width: 100%; }
    #myCarousel ol.carousel-indicators li {
      text-indent: inherit;
      width: 100%;
      height: auto;
      padding: 0 10px 0 0;
      margin: 0;
      border: 0;
      background: none;
      text-align: left;
      position: relative; }
      #myCarousel ol.carousel-indicators li:after {
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background: #F1F3F4; }
      #myCarousel ol.carousel-indicators li h3 {
        font-size: 18px;
        color: #9BA8B9;
        margin: 0 0 20px; }
        @media (min-width: 768px) and (max-width: 991px) {
          #myCarousel ol.carousel-indicators li h3 {
            margin: 0 0 10px;
            font-size: 17px; } }
        @media (min-width: 320px) and (max-width: 767px) {
          #myCarousel ol.carousel-indicators li h3 {
            font-size: 14px;
            margin: 0 0 10px; } }
      #myCarousel ol.carousel-indicators li.active {
        margin: 0; }
        #myCarousel ol.carousel-indicators li.active h3 {
          color: #1F262F; }
        #myCarousel ol.carousel-indicators li.active:after {
          background: #0C59D3; }
      #myCarousel ol.carousel-indicators li:last-child h3 {
        margin: 0; }

span.date {
  font-size: 16px;
  color: #9BA8B9;
  display: block;
  margin: 0 0 10px; }
  @media (min-width: 320px) and (max-width: 767px) {
    span.date {
      font-size: 12px;
      margin: 0 0 3px; } }

.tag.top {
  font-size: 12px;
  color: #fff;
  font-family: Alegreya Sans, serif;
  text-transform: uppercase;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  padding: 0 20px;
  background: #0E326C;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; }

ul.news-list {
  overflow: hidden;
  padding-bottom: 20px; }
  ul.news-list li {
    float: left;
    margin: 0 20px 20px 0;
    width: calc((100% - 40px) / 3); }
    ul.news-list li:nth-child(3n) {
      margin: 0 0 20px; }
    @media (min-width: 320px) and (max-width: 767px) {
      ul.news-list li {
        width: 100%;
        margin: 0 0 20px; } }
    ul.news-list li .img-wrapper {
      display: block;
      position: relative;
      overflow: hidden;
      margin-bottom: 20px; }
      ul.news-list li .img-wrapper img {
        position: absolute;
        display: block;
        margin: auto;
        top: -9999px;
        bottom: -9999px;
        right: -9999px;
        left: -9999px;
        z-index: 0;
        max-height: 100%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto; }
      ul.news-list li .img-wrapper .tag {
        font-size: 12px;
        color: #fff;
        font-family: Alegreya Sans, serif;
        text-transform: uppercase;
        height: 28px;
        line-height: 28px;
        display: inline-block;
        padding: 0 20px;
        background: #0E326C;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1; }
    ul.news-list li span.date {
      font-size: 16px;
      color: #9BA8B9;
      display: block;
      margin: 0 0 20px; }
      @media (min-width: 768px) and (max-width: 991px) {
        ul.news-list li span.date {
          margin: 0 0 10px; } }
      @media (min-width: 320px) and (max-width: 767px) {
        ul.news-list li span.date {
          margin-bottom: 10px;
          font-size: 14px; } }
    ul.news-list li h4 {
      margin-bottom: 40px; }
      @media (min-width: 768px) and (max-width: 991px) {
        ul.news-list li h4 {
          margin-bottom: 20px;
          font-size: 16px; } }
      ul.news-list li h4 a {
        font-size: 18px;
        color: #697B93; }
        ul.news-list li h4 a:hover {
          color: #1F262F; }
    ul.news-list li .read-more {
      display: inline-block;
      color: #9BA8B9;
      font-size: 14px;
      overflow: hidden;
      line-height: 20px;
      text-transform: uppercase;
      font-family: Alegreya Sans, serif; }
      ul.news-list li .read-more i {
        line-height: 20px !important;
        font-size: 20px;
        margin-left: 5px; }
      ul.news-list li .read-more:hover {
        color: #1F262F; }

@media (min-width: 320px) and (max-width: 767px) {
  .news .col-sm-3 {
    text-align: center;
    margin: 0 0 20px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .news .col-sm-3 #datepicker {
    width: 80%;
    margin: 0 auto;
    max-width: 200px; } }

.datepicker {
  position: relative;
  cursor: pointer; }
  .datepicker .input-group-addon {
    background: #fff; }
  .datepicker .form-control {
    background: none;
    cursor: pointer;
    font-family: Alegreya Sans, serif; }

.news-content {
  max-width: 820px;
  margin: 0 auto; }

.news-content, .text {
  background: #fff; }
  .news-content span.date, .text span.date {
    font-size: 16px;
    color: #9BA8B9;
    display: block;
    margin: 0 0 20px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .news-content span.date, .text span.date {
        margin: 0 0 10px; } }
    @media (min-width: 320px) and (max-width: 767px) {
      .news-content span.date, .text span.date {
        margin-bottom: 10px;
        font-size: 14px; } }
  .news-content h1, .text h1 {
    font-family: "Alegreya", serif;
    font-weight: 700;
    font-size: 32px;
    color: #1F262F;
    margin-bottom: 20px; }
    @media (min-width: 320px) and (max-width: 767px) {
      .news-content h1, .text h1 {
        font-size: 24px; } }
  .news-content h3, .text h3 {
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 700;
    color: #1F262F; }
  .news-content h6, .text h6 {
    font-size: 18px;
    font-family: 'Alegreya', serif;
    margin-bottom: 20px; }
  .news-content p, .text p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #697B93; }
  .news-content .load-more, .text .load-more {
    margin-top: 20px; }
  .news-content .video, .text .video {
    margin-bottom: 20px; }
    @media (min-width: 320px) and (max-width: 480px) {
      .news-content .video iframe, .text .video iframe {
        max-height: 320px; } }

#myCarousel1 {
  margin-bottom: 40px; }
  #myCarousel1 .img-wrapper {
    position: relative;
    overflow: hidden; }
    #myCarousel1 .img-wrapper img {
      position: absolute;
      display: block;
      margin: auto;
      top: -9999px;
      bottom: -9999px;
      right: -9999px;
      left: -9999px;
      z-index: 0;
      max-height: 100%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto; }
    #myCarousel1 .img-wrapper .tag {
      font-size: 12px;
      color: #fff;
      font-family: Alegreya Sans, serif;
      text-transform: uppercase;
      height: 28px;
      line-height: 28px;
      display: inline-block;
      padding: 0 20px;
      background: #0E326C;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
  #myCarousel1 .carousel-control {
    background: none;
    transition: all 0.5s;
    opacity: 0;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center; }
    #myCarousel1 .carousel-control .material-icons {
      font-size: 40px; }
    #myCarousel1 .carousel-control:hover {
      opacity: 0.7; }

.lightgallery {
  overflow: hidden;
  padding-top: 20px; }
  .lightgallery a {
    width: calc(100% / 4);
    box-sizing: border-box;
    border: 2.5px solid #fff;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    overflow: hidden;
    text-decoration: none;
    position: relative; }
    @media (min-width: 320px) and (max-width: 480px) {
      .lightgallery a {
        width: 50%; } }
    .lightgallery a:before {
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: #0E326C;
      content: '';
      transition: all 0.3s;
      opacity: 0; }
    .lightgallery a:after {
      z-index: 2;
      width: 40px;
      height: 40px;
      content: '+';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px;
      background: #0C59D3;
      text-align: center;
      line-height: 38px;
      font-size: 18px;
      color: #fff;
      transition: all 0.4s;
      opacity: 0; }
    .lightgallery a img {
      max-width: none;
      max-height: none; }
    .lightgallery a .element_horizont {
      max-height: 100%; }
    .lightgallery a .element_vertical {
      max-width: 100%; }
    .lightgallery a:hover {
      cursor: pointer; }
      .lightgallery a:hover:before {
        opacity: .7; }
      .lightgallery a:hover:after {
        opacity: 1; }

.lg-sub-html h4 {
  color: #fff;
  font-family: Alegreya Sans, serif; }

.lg-sub-html p {
  color: #fff;
  font-family: Alegreya Sans, serif; }

.forum {
  padding: 20px 0;
  background: #E5E5E5; }
  .forum main {
    padding: 0 30px 0 0; }
    @media (min-width: 768px) and (max-width: 1199px) {
      .forum main {
        padding: 0 10px 0 0; } }
    @media (max-width: 768px) {
      .forum main {
        padding: 0 10px; } }
    @media (min-width: 768px) {
      .forum main {
        float: left !important; } }
  .forum aside {
    padding: 0 0 0 30px; }
    @media (min-width: 768px) and (max-width: 1199px) {
      .forum aside {
        padding: 0 0 0 10px; } }
    @media (max-width: 768px) {
      .forum aside {
        padding: 0 10px 20px; } }
    @media (min-width: 768px) {
      .forum aside {
        float: right !important; } }

.forum-header {
  display: flex;
  justify-content: space-between;
  border: 1px solid #F1F3F4;
  background: #fff;
  padding: 0 0 0 20px;
  margin-bottom: 20px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .forum-header {
      padding: 0 0 0 10px; } }
  @media (max-width: 480px) {
    .forum-header {
      display: block;
      padding: 0 20px; } }
  .forum-header .datepicker {
    padding: 13px 0; }
    @media (min-width: 320px) and (max-width: 991px) {
      .forum-header .datepicker {
        padding: 8px 0; } }
    .forum-header .datepicker span, .forum-header .datepicker input {
      border: 0;
      background: none;
      box-shadow: none;
      text-align: center;
      font-family: Alegreya Sans, serif;
      padding: 0;
      font-size: 16px; }
      @media (max-width: 480px) {
        .forum-header .datepicker span, .forum-header .datepicker input {
          border: 1px solid #F1F3F4;
          border-left: none; } }
    @media (max-width: 480px) {
      .forum-header .datepicker span {
        border-right: 0;
        padding-left: 10px;
        border-left: 1px solid #F1F3F4; } }

.forum-nav {
  text-align: left; }
  .forum-nav li {
    display: inline-block; }
    .forum-nav li a {
      color: #697B93;
      text-decoration: none;
      padding: 20px 10px;
      display: block;
      position: relative;
      font-family: Alegreya Sans, serif; }
      @media (min-width: 320px) and (max-width: 991px) {
        .forum-nav li a {
          padding: 15px 10px; } }
    .forum-nav li.active a, .forum-nav li:hover a {
      font-weight: 700;
      color: #0C59D3; }
      .forum-nav li.active a:after, .forum-nav li:hover a:after {
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        height: 2px;
        background: #0C59D3; }

.forum-item {
  border: 1px solid #F1F3F4;
  background: #fff;
  margin-bottom: 20px; }
  .forum-item .top {
    padding: 20px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .forum-item .top {
        padding: 15px 20px; } }
  .forum-item h2 {
    margin-bottom: 20px;
    overflow: hidden; }
    @media (min-width: 320px) and (max-width: 991px) {
      .forum-item h2 {
        margin-bottom: 10px; } }
    .forum-item h2 a {
      color: #1F262F;
      font-size: 24px;
      font-family: Alegreya Sans, serif;
      width: calc(100% - 25px);
      float: left; }
      @media (min-width: 320px) and (max-width: 991px) {
        .forum-item h2 a {
          font-size: 20px; } }
    .forum-item h2 i {
      float: right; }
  .forum-item .img-wrapper {
    overflow: hidden; }
    .forum-item .img-wrapper a {
      display: block;
      font-size: 0; }
    .forum-item .img-wrapper img {
      width: 100%;
      height: auto; }
  .forum-item .bottom {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #F1F3F4; }
    @media (min-width: 320px) and (max-width: 991px) {
      .forum-item .bottom {
        padding: 10px 20px; } }
    .forum-item .bottom .views-comments {
      display: flex; }
  .forum-item .comments, .forum-item .views {
    font-size: 18px;
    color: #697B93;
    font-weight: 700;
    margin-right: 30px;
    line-height: 40px; }
    .forum-item .comments i, .forum-item .views i {
      margin-right: 5px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .forum-item .comments, .forum-item .views {
        font-size: 16px; } }
  .forum-item .user {
    overflow: hidden;
    display: flex; }
    .forum-item .user .social-icon {
      margin-left: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden; }
      .forum-item .user .social-icon img {
        width: 40px;
        height: 40px; }
    .forum-item .user h5 {
      font-size: 12px;
      font-family: Alegreya Sans, serif;
      color: #1F262F;
      font-weight: 400;
      padding-top: 10px; }
      .forum-item .user h5 span {
        color: #9BA8B9;
        display: block; }

.auth-reg, .profile {
  background: #fff;
  padding: 30px 20px;
  border: 1px solid #F1F3F4;
  text-align: center;
  font-family: Alegreya Sans, serif;
  margin: 0 0 10px; }
  @media (min-width: 320px) and (max-width: 991px) {
    .auth-reg, .profile {
      padding: 20px; } }
  .auth-reg h3, .profile h3 {
    color: #0E326C;
    font-size: 22px;
    font-weight: 700;
    font-family: Alegreya Sans, serif;
    margin: 0 0 20px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .auth-reg h3, .profile h3 {
        font-size: 18px;
        margin-bottom: 10px; } }
  .auth-reg .form-group, .profile .form-group {
    margin: 0 0 10px;
    display: block; }
    .auth-reg .form-group input, .profile .form-group input {
      width: 100%;
      height: 56px;
      padding: 0 10px;
      border: 1px solid #CFD5DE;
      border-radius: 4px;
      box-shadow: none;
      font-size: 16px;
      color: #1F262F;
      font-family: Alegreya Sans, serif; }
      @media (min-width: 320px) and (max-width: 991px) {
        .auth-reg .form-group input, .profile .form-group input {
          height: 42px;
          line-height: 42px; } }
      .auth-reg .form-group input::placeholder, .profile .form-group input::placeholder {
        font-family: Alegreya Sans, serif;
        color: #9BA8B9; }
  .auth-reg .forgot-pwd, .auth-reg .auth-reg-link, .profile .forgot-pwd, .profile .auth-reg-link {
    display: block;
    margin-bottom: 20px;
    font-size: 16px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .auth-reg .forgot-pwd, .auth-reg .auth-reg-link, .profile .forgot-pwd, .profile .auth-reg-link {
        margin-bottom: 10px; } }
  .auth-reg .main-btn, .profile .main-btn {
    height: 52px;
    line-height: 52px;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    background: #0C59D3;
    border: 0px;
    border-radius: 4px;
    padding: 0;
    display: block;
    width: 100%;
    font-weight: 700;
    margin-bottom: 20px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .auth-reg .main-btn, .profile .main-btn {
        margin-bottom: 10px;
        font-size: 13px;
        height: 40px;
        line-height: 40px; } }
  .auth-reg .social, .profile .social {
    text-align: center; }
    .auth-reg .social span, .profile .social span {
      font-size: 12px;
      color: #697B93;
      display: block;
      margin-bottom: 20px; }
    .auth-reg .social li, .profile .social li {
      display: inline-block;
      margin: 0 5px; }
      @media (min-width: 320px) and (max-width: 991px) {
        .auth-reg .social li, .profile .social li {
          margin: 0 3px 0 0; } }
      .auth-reg .social li a, .profile .social li a {
        border: 2px solid #F1F3F4;
        display: block;
        padding: 0;
        height: 50px;
        width: 50px;
        text-align: center; }
        @media (min-width: 320px) and (max-width: 991px) {
          .auth-reg .social li a, .profile .social li a {
            width: 40px;
            height: 40px; } }
        .auth-reg .social li a img, .profile .social li a img {
          display: block;
          height: 20px;
          margin: 13px auto; }
          @media (min-width: 320px) and (max-width: 991px) {
            .auth-reg .social li a img, .profile .social li a img {
              margin: 8px auto; } }
        .auth-reg .social li a:hover, .profile .social li a:hover {
          border-color: #697B93; }

.profile {
  padding: 0; }
  @media (min-width: 320px) and (max-width: 991px) {
    .profile {
      padding: 0; } }
  .profile .user-info {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #F1F3F4; }
    .profile .user-info .img-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 10px 0 0; }
      .profile .user-info .img-wrapper img {
        width: 40px;
        height: 40px; }
    .profile .user-info h6 {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      color: #697B93;
      text-align: left;
      padding: 5px 0; }
      .profile .user-info h6 span {
        display: block;
        font-size: 12px;
        color: #9BA8B9;
        font-weight: 400; }
        .profile .user-info h6 span a {
          color: #0C59D3;
          margin: 0 0 0 5px; }

.user-nav {
  text-align: left;
  padding-top: 10px; }
  .user-nav li {
    border-bottom: 1px solid #F1F3F4; }
    .user-nav li i {
      color: #CFD5DE;
      margin-right: 20px; }
    .user-nav li a {
      font-size: 16px;
      font-family: "Alegreya", serif;
      color: #697B93;
      padding: 15px 20px;
      display: block; }

.main-forum-btn {
  width: 100%;
  display: block;
  border: 0;
  font-size: 14px;
  color: #fff;
  padding: 0;
  height: 52px;
  border-radius: 4px;
  background: #20DFA6;
  font-weight: 700;
  font-family: Alegreya Sans, serif;
  text-transform: uppercase;
  text-align: center;
  text-wrap: normal;
  line-height: 52px;
  margin-bottom: 10px; }
  .main-forum-btn i {
    margin-right: 5px; }
  .main-forum-btn:hover {
    color: #fff;
    background: #20DFA6;
    text-decoration: none; }

.text h1 {
  font-size: 32px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .text h1 {
      font-size: 24px; } }

.text h2 {
  font-size: 28px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .text h2 {
      font-size: 22px; } }

.text h3 {
  font-size: 22px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .text h3 {
      font-size: 18px; } }

.text h4 {
  font-size: 22px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .text h4 {
      font-size: 18px; } }

.text h5 {
  font-size: 18px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .text h5 {
      font-size: 16px; } }

.text h6 {
  font-size: 18px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .text h6 {
      font-size: 16px; } }

.text h1, .text h2, .text h3, .text h4, .text h5, .text h6, .text p {
  margin-bottom: 20px; }
  @media (min-width: 320px) and (max-width: 768px) {
    .text h1, .text h2, .text h3, .text h4, .text h5, .text h6, .text p {
      margin-bottom: 10px; } }

.date-container {
  padding: 20px 30px;
  border: 1px solid #CFD5DE;
  margin: 20px 0;
  border-radius: 4px; }
  @media (max-width: 992px) {
    .date-container {
      padding: 20px;
      text-align: center; } }

.datepicker-container {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 992px) {
    .datepicker-container {
      display: block;
      text-align: center; } }
  .datepicker-container .input-group {
    max-width: 260px;
    width: calc((100% - 220px) / 2);
    cursor: pointer;
    padding: 0;
    margin: 0 auto 10px; }
    @media (max-width: 992px) {
      .datepicker-container .input-group {
        width: auto; } }
  .datepicker-container button {
    width: 200px;
    height: 52px;
    padding: 0;
    background: #0C59D3;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    border-radius: 4px;
    font-size: 14px;
    font-family: Alegreya Sans, serif;
    border: 2px solid #0C59D3;
    transition: all 0.3s; }
    .datepicker-container button:hover {
      background: #fff;
      border-color: #0C59D3;
      color: #0C59D3; }
    @media (max-width: 768px) {
      .datepicker-container button {
        height: 42px; } }
  .datepicker-container input, .datepicker-container span, .datepicker-container .form-control {
    background: none;
    border-color: #CFD5DE;
    height: 52px;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: none; }
    @media (max-width: 768px) {
      .datepicker-container input, .datepicker-container span, .datepicker-container .form-control {
        height: 42px; } }
    .datepicker-container input:hover, .datepicker-container input:focus, .datepicker-container span:hover, .datepicker-container span:focus, .datepicker-container .form-control:hover, .datepicker-container .form-control:focus {
      border-color: #0C59D3; }

.panel-group .panel {
  border-radius: 0;
  border: 1px solid #F1F3F4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: "Alegreya", serif;
  margin: 0 !important; }
  .panel-group .panel .panel-heading {
    border-radius: 0 !important;
    padding: 0; }
    .panel-group .panel .panel-heading h4 {
      margin: 0; }
      .panel-group .panel .panel-heading h4 span.date {
        color: #9BA8B9;
        font-size: 14px;
        font-weight: 400;
        font-family: "Alegreya", serif;
        margin-bottom: 5px; }
      .panel-group .panel .panel-heading h4 a {
        font-family: Alegreya Sans, serif;
        font-size: 18px;
        line-height: 24px;
        display: block;
        margin: 4px 0;
        color: #0E326C; }
        .panel-group .panel .panel-heading h4 a i {
          float: right;
          color: #0C59D3;
          display: none; }
          .panel-group .panel .panel-heading h4 a i.remove {
            display: block; }
        .panel-group .panel .panel-heading h4 a.collapsed i.remove {
          display: none; }
        .panel-group .panel .panel-heading h4 a.collapsed i.add {
          display: block; }
      .panel-group .panel .panel-heading h4 label {
        font-size: 16px;
        font-weight: 400;
        color: #1F262F;
        font-family: "Alegreya", serif; }
  .panel-group .panel .panel-collapse .panel-body {
    padding: 20px 0 0;
    border: none; }
    .panel-group .panel .panel-collapse .panel-body p {
      font-size: 16px;
      color: #000;
      margin-bottom: 10px; }
    .panel-group .panel .panel-collapse .panel-body ul {
      padding-left: 20px;
      margin-bottom: 20px;
      color: #697B93; }
      .panel-group .panel .panel-collapse .panel-body ul li {
        list-style-type: disc;
        font-size: 14px; }
    .panel-group .panel .panel-collapse .panel-body span {
      display: block;
      margin-bottom: 20px;
      color: #697B93;
      font-size: 14px; }
      .panel-group .panel .panel-collapse .panel-body span a {
        margin-left: 5px;
        color: #0C59D3; }

ul.tenders-list {
  overflow: hidden;
  margin: 0;
  padding: 0; }
  ul.tenders-list li {
    width: calc((100% - 40px) / 3);
    margin-right: 20px;
    margin-bottom: 20px;
    float: left;
    border: 1px solid #CFD5DE; }
    ul.tenders-list li:nth-child(3n) {
      margin-right: 0; }
    @media (min-width: 520px) and (max-width: 991px) {
      ul.tenders-list li {
        width: calc((100% - 40px) / 2);
        margin: 0 10px 20px; }
        ul.tenders-list li:nth-child(3n) {
          margin-right: 10px; } }
    @media (min-width: 320px) and (max-width: 519px) {
      ul.tenders-list li {
        width: 100%;
        margin: 0 0 20px; }
        ul.tenders-list li:nth-child(3n) {
          margin-right: 0; } }
    ul.tenders-list li .img-wrapper {
      position: relative;
      overflow: hidden;
      display: block;
      font-size: 0;
      margin-bottom: 20px; }
      ul.tenders-list li .img-wrapper img {
        position: absolute;
        display: block;
        margin: auto;
        top: -9999px;
        bottom: -9999px;
        right: -9999px;
        left: -9999px;
        z-index: 0;
        max-height: 100%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto; }
    ul.tenders-list li .top {
      padding: 16px 20px;
      border-bottom: 1px solid #CFD5DE; }
    ul.tenders-list li .bottom {
      padding: 16px 20px; }
      ul.tenders-list li .bottom > div {
        display: flex;
        justify-content: space-between; }
      ul.tenders-list li .bottom p {
        font-size: 16px;
        color: #1F262F;
        margin-bottom: 20px; }
        ul.tenders-list li .bottom p span {
          font-size: 14px;
          color: #9BA8B9;
          display: block; }
        ul.tenders-list li .bottom p i {
          color: #9BA8B9;
          float: left;
          margin-right: 5px;
          margin-top: 5px;
          font-size: 18px; }
    ul.tenders-list li h2 {
      font-size: 22px; }
      ul.tenders-list li h2 a {
        color: #1F262F; }

.single-tender-content p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #697B93; }

.single-tender-content h5 {
  margin-bottom: 0; }

.single-tender-content h3 {
  margin-bottom: 20px; }

.single-tender-content ul {
  padding-left: 20px; }
  .single-tender-content ul li {
    list-style: disc;
    font-size: 14px;
    color: #697B93; }

.other-tenders {
  margin-top: 10px; }

@media (max-width: 768px) {
  .col-md-5 {
    margin-bottom: 30px; } }

.bank h3, .contacts h3 {
  margin-bottom: 10px; }

.bank p, .contacts p {
  font-size: 16px;
  color: #1F262F; }
  .bank p b, .contacts p b {
    display: block;
    font-size: 18px;
    font-family: Alegreya Sans, serif; }

.bank ol, .contacts ol {
  list-style: decimal;
  padding-left: 10px; }
  .bank ol li span, .contacts ol li span {
    float: right;
    margin-left: 10px; }

.bank a, .contacts a {
  display: block;
  margin-bottom: 20px; }
  .bank a i, .contacts a i {
    margin-right: 10px; }

.contacts p {
  font-size: 18px;
  font-family: Alegreya Sans, serif; }
  .contacts p b {
    display: inline-block;
    width: 40%; }
