.paginations {
	text-align: center;
	.pagination {
		.page-item {
			width: 40px;
			height: 40px;
			margin: 0 4px;
			display: inline-block;
			a {
				padding: 0;
				display: block;
				line-height: 40px;
				font-size: 14px;
				color: $gray;
				font-family: $aSans;
				border: 2px solid $lightGray;
				width: 40px;
				&:hover {
					background: none;
					border-color: $blue; } }
			&.disabled {
				a {
					border: 2px solid $blue; } } } } }
