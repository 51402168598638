
.main-navigation {
	background-image: linear-gradient(to right bottom, #0e326c, #0f3777, #103c81, #11418c, #134697);
	z-index: 98;
	border-radius: 0;
	border: 0;
	margin: 0;
	.navbar-header {
		overflow: hidden;
		padding: 0;
		.logo {
			margin: 0;
			padding: 17px 0;
			height: auto;
			img {
				height: 66px;
				width: 94px; } } } }

.search-btn {
	color: #fff;
	line-height: 9px;
	padding: 38px 20px;
	transition: all 0.3s;
	position: relative;
	&:hover, &:focus {
		color: $green; }
	.material-icons {
		font-size: 24px; }
	span {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 6px 8px 6px;
		border-color: transparent transparent #fff transparent;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -4px;
		display: none;
		&.active {
			display: block; } } }

/** hidden search field * */
#searchbar {
	display: none;
	width: 100%;
	height: auto;
	z-index: 9999;
	position: absolute;
	top: 100px;
	border: none;
	.container {
		overflow: hidden;
		padding-bottom: 20px; }
	form {
		padding: 20px;
		float: right !important;
		margin: 0;
		width: 50%;
		position: relative;
		background: #fff;
		box-shadow: 0 3px 10px rgba(0,0,0,.2);
		border-radius: 0 0 10px 10px;
		.form-group {
			width: 100%; } } }

#s {
	display: block;
	width: 100%;
	border: 1px solid $gray3;
	outline: none;
	padding: 18px 15px;
	height: 56px;
	line-height: 20px;
	font-size: 16px;
	color: $gray;
	box-shadow: none;
	::-webkit-input-placeholder, &::placeholder {
		font-family: $aSans; } }

#searchsubmit {
	display: block;
	background: none;
	color: $blue;
	border: 0;
	outline: none;
	cursor: pointer;
	height: 56px;
	padding: 0;
	position: absolute;
	top: 20px;
	right: 20px;
	width: 50px;
	font-size: 20px;
	line-height: 12px;
	&:active {
		box-shadow: none; } }

.language {
	float: right;
	padding: 30px 15px;
	overflow: hidden;
	li {
		padding: 0 5px;
		float: left;
		a {
			width: 40px;
			height: 40px;
			border: 1px solid rgba(255,255,255,0.1);
			display: flex;
			padding: 0;
			border-radius: 50%;
			img {
				width: 20px;
				height: 20px;
				margin: auto; } }
		&.active, &:hover {
			a {
				background: none !important;
				border: 1px solid $green; } } } }

.main-nav {
	padding-left: 15px;
	li {
		padding: 0 20px;
		a {
			font-size: 14px;
			padding: 40px 0;
			font-family: $aSans;
			font-weight: 500;
			color: #fff !important;
			line-height: 20px;
			text-transform: uppercase;
			position: relative;
			&:after {
				display: none; }
			.material-icons {
				font-size: 20px;
				line-height: 17px; } }
		&:first-child {
			a {
				line-height: 20px; } }
		&.active, &:hover, &:focus, &:focus-within, &.open {
			a {
				color: $green !important;
				background: none !important;
				&:before {
					width: 100%;
					content: '';
					height: 2px;
					position: absolute;
					bottom: 0;
					left: 0;
					background: $green; } } } }
	.dropdown-menu {
		padding: 20px;
		min-width: 240px;
		left: 10px;
		border: 0;
		box-shadow: 0 3px 10px rgba(0,0,0,.2);
		li {
			padding: 0;
			a {
				padding: 6px 0;
				font-size: 16px;
				text-transform: capitalize;
				font-weight: 400;
				color: $gray !important;
				text-decoration: none;
				&:before {
					display: inline-block;
					content: '» ';
					width: auto;
					height: auto;
					background: none;
					position: static;
					margin-right: 5px; }
				&:hover, &.active {
					color: $black !important; } } } } }

@media (min-width: 992px) and (max-width: 1200px) {
	.main-nav {
		li {
			padding: 0 10px; } }
	.language {
		padding: 30px 5px; }
	.search-btn {
		padding-right: 0;
		padding-left: 10px; } }

@media (min-width: 768px) and (max-width: 991px) {
	#searchbar {
		top: 80px; }
	.search-btn {
		margin-right: 50px;
		padding: 28px 20px; }
	.language {
		padding: 20px 10px; }
	.main-navigation {
		.container {
			> .row {
				position: relative; } }
		.navbar-toggle {
			margin: 23px 0;
			border-color: #fff;
			position: absolute;
			right: 0;
			transition: all 0.3s;
			.icon-bar {
				background: #fff; }
			&:hover, &:focus {
				background: none;
				border-color: $green;
				.icon-bar {
					background: $green; } } }
		.navbar-header {
			.logo {
				padding: 7px 0; } }
		.main-nav {
			margin: 0;
			padding: 0;
			text-align: center;
			.dropdown-menu {
				padding: 10px 0;
				text-align: center;
				li {
					a {
						color: #fff !important;
						&:active {
							color: $black !important; }
						&:before {
							width: auto !important;
							margin: 0 5px 0 0 !important; } } } } } }

	.navbar-nav .open .dropdown-menu {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		> li > a {
			line-height: 20px;
			padding: 5px 15px 5px 25px; }
		.dropdown-header {
			padding: 5px 15px 5px 25px; } }
	.dropdown-menu > li > a {
		display: block;
		padding: 3px 20px;
		clear: both;
		font-weight: normal;
		line-height: 1.42857143;
		color: #333;
		white-space: nowrap; }
	.navbar-toggle {
		display: block; }
	.navbar-collapse {
		border-top: 1px solid rgba(255,255,255,0.1) !important;
		box-shadow: none;
		padding: 0;
		&.collapse {
			display: none !important; } }
	.navbar-nav.main-nav {
		float: none !important;
		/*margin: 7.5px -15px; */
		margin: 0;
		> li {
			float: none;
			padding: 0;
			> a {
				padding-top: 10px;
				padding-bottom: 10px; }
			&.active, &:hover, &:focus, &:focus-within, &.open {
				a {
					&:before {
						width: 200px;
						left: 50%;
						margin-left: -100px; } } } } }
	.navbar-text {
		float: none;
		margin: 15px 0; }
	/* since 3.1.0 */
	.navbar-collapse.collapse.in {
		display: block !important; }
	.collapsing {
		overflow: hidden !important; } }

@media (min-width: 320px) and (max-width: 767px) {
	#searchbar {
		top: 80px;
		form {
			width: 100%; } }
	.search-btn {
		margin-right: 50px;
		padding: 28px 20px; }
	.language {
		padding: 20px 10px; }
	.main-navigation {
		.container {
			> .row {
				position: relative; } }
		.navbar-toggle {
			margin: 23px 0;
			border-color: #fff;
			position: absolute;
			right: 0;
			transition: all 0.3s;
			.icon-bar {
				background: #fff; }
			&:hover, &:focus {
				background: none;
				border-color: $green;
				.icon-bar {
					background: $green; } } }
		.navbar-header {
			float: left;
			.logo {
				padding: 7px 0; } }
		.main-nav {
			margin: 0;
			padding: 0;
			text-align: center;
			.dropdown-menu {
				padding: 10px 0;
				text-align: center;
				li {
					a {
						color: #fff !important;
						&:active {
							color: $black !important; }
						&:before {
							width: auto !important;
							margin: 0 5px 0 0 !important; } } } } } }

	.navbar-nav .open .dropdown-menu {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		> li > a {
			line-height: 20px;
			padding: 5px 15px 5px 25px; }
		.dropdown-header {
			padding: 5px 15px 5px 25px; } }
	.dropdown-menu > li > a {
		display: block;
		padding: 3px 20px;
		clear: both;
		font-weight: normal;
		line-height: 1.42857143;
		color: #333;
		white-space: nowrap; }
	.navbar-toggle {
		display: block; }
	.navbar-collapse {
		border-top: 1px solid rgba(255,255,255,0.1) !important;
		box-shadow: none;
		padding: 0;
		&.collapse {
			display: none !important; } }
	.navbar-nav.main-nav {
		float: none !important;
		/*margin: 7.5px -15px; */
		margin: 0;
		> li {
			float: none;
			padding: 0;
			> a {
				padding-top: 10px;
				padding-bottom: 10px; }
			&.active, &:hover, &:focus, &:focus-within, &.open {
				a {
					&:before {
						width: 200px;
						left: 50%;
						margin-left: -100px; } } } } }
	.navbar-text {
		float: none;
		margin: 15px 0; }
	/* since 3.1.0 */
	.navbar-collapse.collapse.in {
		display: block !important; }
	.collapsing {
		overflow: hidden !important; } }

@media (max-width: 520px) {
	.main-navigation {
		.navbar-header {
			.logo {
				padding: 15px 0;
				img {
					height: 50px;
					width: auto; } } } } }

@media (max-width: 380px) {
	.main-navigation {
		.navbar-header {
			float: none; } }
	#searchbar {
		top: 160px; } }

