// ul.buttons
ul.buttons {
	margin: 0;
	padding: 40px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media (min-width: 320px) and (max-width: 767px) {
		display: block;
		overflow: hidden;
		padding: 20px 0 0; }
	li {
		margin-right: 20px;
		width: calc((100% - 60px) / 4);
		text-align: center;
		border: 1px solid $lightGray;
		background: #fff;
		box-sizing: border-box;
		transition: all 0.3s;
		&:hover {
			border-color: $blue; }
		&:last-child {
			margin: 0; }
		@media (min-width: 320px) and (max-width: 767px) {
			width: calc((100% - 20px) / 2);
			float: left;
			&:nth-child(2n) {
				margin: 0 0 20px 0; } }
		@media  (max-width: 480px) {
			width: 100%;
			margin: 0 0 10px;
			&:nth-child(2n) {
				margin: 0 0 10px 0; }
			&:last-child {
				margin: 0; } }
		a {
			font-size: 18px;
			font-family: $aSans;
			display: block;
			padding: 25px 0;
			color: $blue;
			@media (min-width: 768px) and (max-width: 991px) {
				font-size: 14px;
				padding: 15px 0; }
			@media (min-width: 320px) and (max-width: 767px) {
				font-size: 14px;
				padding: 15px 10px; }
			i {
				margin-right: 10px;
				font-size: 16px; }
			&:hover, &:focus {
				color: $blue; } } } }


