
.share {
	position: absolute;
	top: 0;
	left: 0;
	@media (max-width: 991px) {
		position: static;
		margin-bottom: 15px; } }
.social {
	li {
		display: block;
		margin: 0 0 5px;
		@media (min-width: 320px) and (max-width: 991px) {
			margin: 0 3px 0;
			display: inline-block; }
		a {
			border: 2px solid $lightGray;
			display: block;
			padding: 0;
			height: 50px;
			width: 50px;
			text-align: center;
			@media (min-width: 320px) and (max-width: 991px) {
				width: 40px;
				height: 40px; }
			img {
				display: block;
				height: 20px;
				margin: 13px auto;
				@media (min-width: 320px) and (max-width: 991px) {
					margin: 8px auto; } }
			&:hover {
				border-color: $gray; } } } }

.download-file {
	display: flex;
	text-decoration: none;
	justify-content: space-between;
	font-size: 16px;
	color: $black;
	padding: 10px 0;
	border-bottom: 1px solid $lightGray;
	.material-icons {
		transform: rotate(180deg);
		margin-right: 5px;
		color: $blue; }
	&:hover {
		text-decoration: underline;
		color: $blue; } }
