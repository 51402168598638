html, body {
	font-size: 16px; }
// body
body {
	font-family: $al;
	color: #697B93;
	background: #e5e5e5;
	display: flex;
	min-height: 100vh;
	flex-direction: column; }

.content-wrapper {
	flex: 1; }

// customise bootstrap styles
.container {
	padding-left: 10px;
	padding-right: 10px;
	&.posrel {
		position: relative; }
	@media (min-width: 1200px) {
		width: 1200px; }
	@media (min-width: 992px) {
		width: 970px; }
	>.row {
		margin-right: 0;
		margin-left: 0; } }

.row {
	margin-right: -10px;
	margin-left: -10px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
	padding-left: 10px;
	padding-right: 10px; }

.nopadding {
	padding: 0 !important; }

.material-icons {
	vertical-align: middle !important; }

section {
	padding: 60px 0;
	background: #fff;
	@media (max-width: 992px) {
		padding: 40px 0; }
	@media (max-width: 768px) {
		padding: 20px 0; }
	h1, h2 {
		margin: 0 0 40px;
		@media (min-width: 768px) and (max-width: 991px) {
			margin: 0 0 20px; }
		@media (min-width: 320px) and (max-width: 767px) {
			font-size: 28px;
			margin: 0 0 20px; } } }

.load-more {
	display: block;
	padding: 16px 0;
	border: 2px solid $lightGray;
	font-weight: 700;
	font-family: $aSans;
	color: $blue;
	text-align: center;
	text-transform: uppercase;
	&:hover, &:focus {
		border-color: $blue;
		color: $blue; } }

.other-news {
	margin-top: 10px; }

.mt10 {
	margin-top: 10px; }
